import { CircularProgress } from "@mui/material";

const Container = ({ isLoading, error, children }) => (
  <div className="pt-4" style={{ minHeight: "100vh", background: "#ddd" }}>
    <div
      className="mx-auto pt-4 pb-8 px-4 rounded-md bg-white"
      style={{ maxWidth: "400px" }}
    >
      {isLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="text-center font-bold">
          {error?.data?.message || "An unexpected error occurred"}
        </div>
      ) : (
        children
      )}
    </div>
  </div>
);

export default Container;
