import { all } from 'redux-saga/effects';
import user from './user';
import superAdmin from './superAdmin';
import pack from './pack';
import product from './product';
import voucherConfig from './voucherConfig';
import common from './common';
import client from './client';
import merchant from './merchant';

function* ugvSaga() {
    yield all([
        ...user,
        ...superAdmin,
        ...pack,
        ...product,
        ...voucherConfig,
        ...common,
        ...client,
        ...merchant,
    ])
}

export default ugvSaga;