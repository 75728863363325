import * as Actions from "store/actions";

var initialState = {
  packs: [],
};

const pack = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_PACKS_SUCCESS:
      return Object.assign({}, state, { packs: action.packs });

    case "IS_FETCHING_USERS":
      return Object.assign({}, state, { isFetchingUsers: action.status });

    case "IS_CREATING_USER":
      return Object.assign({}, state, { isCreatingUser: action.status });

    case "LOGIN":
      return { ...state, isLoggedIn: true, data: action.payload };

    case "LOGOUT":
      return { ...initialState, token: null };

    case "SET_TOKEN":
      return Object.assign({}, state, {
        token: action.token,
        isLoggedIn: true,
      });
    // case "CREATE_USER":
    //   return { ...state, users: [...state.users, action.newUser] };

    // case "GET_USERS":
    //   return { ...state, users: action.users };
    case "SET_USERS":
    case Actions.GET_USERS_SUCCESS:
    case Actions.CREATE_USER_SUCCESS:    
    case Actions.DELETE_USER_SUCCESS:
      return { ...state, users: action.users };

    case "SET_CREATE_USER_STATUS":
      return Object.assign({}, state, { createUserStatus: action.status });

    case "SET_USER_DATA":
      return { ...state, data: action.payload };

    case Actions.VERIFY_USER_SUCCESS:
      return { ...state, isVerified: true };

    default:
      return state;
  }
};

export default pack;
