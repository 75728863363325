import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRequestVoucherVerificationCodeMutation } from "services/pub";
import { useSendVoucherVerificationCodeMutation } from "services/pub";
import { VoucherContext } from "views/redemption-portal/context/VoucherContext";

const useVerification = () => {
  const { merchantcode } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(null);
  const voucherContext = useContext(VoucherContext);
  const { voucher } = voucherContext || {};
  const { msisdn, id: voucherId } = voucher || {};
  const [isVerified, setIsVerified] = useState(null);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const [
    sendVoucherVerificationReq,
    {
      isLoading: isLoadingSendVerificationCode,
      isError,
      error: errorSendVerificationCode,
      data: dataSendVerificationCode,
    },
  ] = useSendVoucherVerificationCodeMutation();
  const [
    requestVoucherVerificationCode,
    {
      isLoading: isLoadingRequestVoucherVerificationCode,
      error: errorRequestVoucherVerificationCode,
      data: dataRequestVoucherVerificationCode,
    },
  ] = useRequestVoucherVerificationCodeMutation();

  const handleSubmitVerificationCode = (verificationCode) => {
    sendVoucherVerificationReq({
      verificationCode,
      voucherId,
    });
  };

  const handleSelectVerificationCodeMethod = (option) => {
    setVerificationMethod(option);
    requestVoucherVerificationCode({
      voucherId,
    });
  };

  const changeVerificationMethod = () => {
    setErrorMessage(null);
    setIsVerificationCodeSent(false);
  };

  useEffect(() => {
    if (dataSendVerificationCode) {
      setIsVerified(true);
    }
  }, [dataSendVerificationCode]);

  useEffect(() => {
    if (errorSendVerificationCode) {
      setErrorMessage(
        errorSendVerificationCode?.data?.message ||
          "An unexpected error occurred"
      );
    }
  }, [errorSendVerificationCode]);

  useEffect(() => {
    if (dataRequestVoucherVerificationCode) {
      setIsVerificationCodeSent(true);
    }
  }, [dataRequestVoucherVerificationCode]);

  return {
    msisdn,
    isVerified,
    setIsVerified,
    isVerificationCodeSent,
    changeVerificationMethod,
    handleSelectVerificationCodeMethod,
    errorMessage,
    isLoadingRequestVoucherVerificationCode,
    isLoadingSendVerificationCode,
    handleSubmitVerificationCode,
    errorRequestVoucherVerificationCode,
  };
};

export default useVerification;
