import * as Actions from "store/actions";

var initialState = {
  products: [],
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_PRODUCTS_SUCCESS:
      return Object.assign({}, state, { products: action.products });
    
    default:
      return state;
  }
};

export default product;
