import jwt_decode from "jwt-decode"
import * as Actions from "store/actions";

const tokenStr = window.localStorage.getItem('superAdminToken');
let data = null;
let token = null;
if (tokenStr != null) {
  let tokenData = JSON.parse(tokenStr);
  token = tokenData?.token ?? null
  data = jwt_decode(token);
}

var initialState = {
  isLoggedIn: false,
  data,
  systemConfig: {
    isLoading: false,
    // data: [],
  },
  regions: {
    isLoading: false,
    data: [],
  },
  industries: {
    isLoading: false,
    data: [],
  },
  generalConfig: {
    isLoading: false,
    data: [],
  },
  financialInstitutions: {
    isLoading: false,
    data: [],
  },
  isLoggingIn: false,
  token,
};

const superAdmin = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGIN_SUPER_ADMIN_SUCCESS:
      return { ...state, isLoggedIn: true, data: action.payload };

    case Actions.LOGOUT_SUPER_ADMIN:
      return { ...initialState, token: null };

    case Actions.SET_SUPER_ADMIN_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
        isLoggedIn: true,
      });

    case Actions.GET_SYSTEM_CONFIG:
      return { ...state, systemConfig: { isLoading: true } }

    case Actions.GET_SYSTEM_CONFIG_SUCCESS:
      const { regions, industries, financialInstitutions, generalConfig } = action.payload;
      return {
        ...state,
        systemConfig: {
          isLoading: false,
        },
        generalConfig: { ...state.generalConfig, data: generalConfig },
        industries: { ...state.industries, data: industries },
        regions: { ...state.regions, data: regions },
        financialInstitutions: { ...state.financialInstitutions, data: financialInstitutions },
      }

    case Actions.GET_SYSTEM_CONFIG_FAIL:
      return { ...state, systemConfig: { ...state.systemConfig, isLoading: false } }

    case Actions.UPDATE_FINANCIAL_INST:
      return { ...state, financialInstitutions: { ...state.financialInstitutions, isLoading: true } }

    case Actions.UPDATE_FINANCIAL_INST_SUCCESS:
    case Actions.UPDATE_FINANCIAL_INST_FAIL:
      return {
        ...state, financialInstitutions: {
          ...state.financialInstitutions,
          isLoading: false,
          data: action.payload || state.financialInstitutions.data
        }
      }

    case Actions.ADD_REGION: case Actions.UPDATE_REGION: case Actions.DELETE_REGION:
      return { ...state, regions: { ...state.regions, isLoading: true } }

    case Actions.UPDATE_REGION_SUCCESS:
      return {
        ...state, regions: {
          ...state.regions,
          isLoading: false,
          data: action.payload || state.regions.data
        }
      }

    case Actions.ADD_INDUSTRY: case Actions.UPDATE_INDUSTRY: case Actions.DELETE_INDUSTRY:
      return { ...state, industries: { ...state.industries, isLoading: true } }

    case Actions.UPDATE_INDUSTRY_SUCCESS:
      return {
        ...state, industries: {
          ...state.industries,
          isLoading: false,
          data: action.payload || state.industries.data
        }
      }

    case Actions.UPDATE_GENERAL_CONFIG:
      return { ...state, generalConfig: { ...state.generalConfig, isLoading: true } }

    case Actions.UPDATE_GENERAL_CONFIG_SUCCESS:
      return {
        ...state, generalConfig: {
          ...state.generalConfig,
          isLoading: false,
          data: action.payload || state.generalConfig.data
        }
      }

    default:
      return state;
  }
};

export default superAdmin;
