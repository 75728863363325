import axios from "axios";
// import config from "../config/config";
import qs from "qs";

// ADMIN PORTAL REQUEST
const adminServer = axios.create({ baseURL: process.env.REACT_APP_BASEURLSUPER, });

adminServer.interceptors.request.use(async (config) => {
  let accessToken = "";
  var token = window.localStorage.getItem("superAdminToken");
  if (token) {
    const t = JSON.parse(token);
    accessToken = t.token;
  }

  config.headers["Authorization"] = `Bearer ${accessToken}`;

  config.headers["Access-Control-Allow-Headers"] = "*";
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.withCredentials = false;

  return config;
});

adminServer.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const curPath = window.location.pathname;
  if (curPath != '/auth/super') {
    if (error?.response?.status == 401) {
      window.location.href = '/auth/super'
    }
  }
  return Promise.reject(error);
});

// Super admin
export const loginSuperAdmin = (userDetails) => adminServer.post("login", userDetails);
export const getSystemConfig = () => adminServer.get("config");
export const addFinancialInstitutions = (data) => adminServer.post("financial-institution/batch-add", data);
export const updateFinancialInstitution = ({ id, data }) => adminServer.put(`financial-institution/${id}`, data);
export const deleteFinancialInstitutions = (data) => adminServer.post("financial-institution/batch-delete", data);

// Regions
export const addRegions = (data) => adminServer.post(`region/batch-add`, data);
export const updateRegion = ({ id, data }) => adminServer.put(`region/${id}`, data);
export const deleteRegions = (data) => adminServer.post(`region/batch-delete`, data);

// Industries
export const addIndustries = (data) => adminServer.post(`industry/batch-add`, data);
export const updateIndustry = ({ id, data }) => adminServer.put(`industry/${id}`, data);
export const deleteIndustries = (data) => adminServer.post(`industry/batch-delete`, data);

// General config
export const updateGeneralConfigs = (data) => adminServer.post(`general-config/batch-update`, data);

// Organization
export const getClients = () => adminServer.get(`organization/client`);
export const getMerchants = () => adminServer.get(`organization/merchant`);
export const getOrganizationDetails = (id) => adminServer.get(`organization/${id}/details`);
export const activateOrganizations = (data) => adminServer.post(`organization/batch-activate`, data);
export const deactivateOrganizations = (data) => adminServer.post(`organization/batch-deactivate`, data);
export const changeSubscriptionType = (data) => adminServer.post(`organization/batch-subscription-change`, data);
export const updateClientCommission = ({ clientId, commissionId, data }) => adminServer.put(`organization/${clientId}/commission`, { commissionId, data });


// Account balance
export const accountTopup = (data) => adminServer.post(`organization/account-topup`, data);

// Setup fee
export const updateSetupFee = (data) => adminServer.post(`organization/setup-fee`, data);

// Find vouchers
export const findVouchers = (data) => adminServer.get(`voucher`, { params: data });
export const getVoucherCount = () => adminServer.get(`voucher/count`);