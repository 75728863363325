import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";
import jwt_decode from "jwt-decode";

function setToken(data) {
  window.localStorage.setItem("token", JSON.stringify(data));
}

function* signup({ payload: { userDetails, history, onComplete } }) {
  try {
    const response = yield call(API.signup, userDetails);
    history.push("/auth/login");
    yield put({
      type: "SHOW_NOTIFICATION",
      message: "NB: A verification link has been sent to your email.",
      success: true,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: e.response?.data?.message ?? 'An error occurred',
      success: false,
    }); console.log(e)
    console.log(onComplete, '');
  }
}

function* login({ payload: { userDetails, history, onComplete } }) {
  try {
    const { data } = yield call(API.login, userDetails);
    const loginData = jwt_decode(data.token);
    yield put({ type: "LOGIN", payload: loginData });
    yield put({ type: "SET_TOKEN", token: data.token })
    yield call(setToken, { token: data.token });
    history.push("/admin/dashboard");
  } catch (e) {
    onComplete({ success: false, message: e?.response?.data?.message });
    console.log(e);
  }
}

function* updateUserPassword({ payload: password }) {
  try {
    let userData = yield select(({ user: { data } }) => data);
    userData.forcePasswordChange = false;
    yield call(API.updateUserPassword, { updates: { password } });
    yield put({ type: "SET_USER_DATA", payload: userData });
    yield put({
      type: "SHOW_NOTIFICATION",
      message: "Password successfully updated",
      success: true,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: "Password could not be updated due to an error",
      success: false,
    });
  }
}

function* verify({ payload: OTP }) {
  try {
    const { data } = yield call(API.verify, { OTP });
    console.log(data);
    yield call(setToken, { token: data.token });
    yield put({ type: Actions.VERIFY_USER_SUCCESS });
    yield put({ type: "SET_USER_DATA", payload: data });
    //    history.push('/admin/')
  } catch (e) {
    console.log(e);
  }
}

function* deleteUser({ payload: userId }) {
  try {
    const users = yield select(({ user: { users } }) => users);
    const newUsers = users.filter((v) => v._id != userId);
    yield call(API.deleteUser, userId);
    yield put({ type: Actions.DELETE_USER_SUCCESS, users: newUsers });
    yield put({
      type: "SHOW_NOTIFICATION",
      message: "User successfully deleted",
      success: true,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to delete user. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* createUser({ payload: userDetails }) {
  try {
    const currentUsers = yield select(({ user: { users } }) => users);
    const { data: user } = yield call(API.createUser, userDetails);
    yield put({ type: Actions.CREATE_USER_SUCCESS, users: [...currentUsers, user] });
    yield put({
      type: "SHOW_NOTIFICATION",
      message: "User successfully created",
      success: true,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to create user. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* getUsers() {
  try {
    const users = yield call(API.getUsers);
    yield put({ type: Actions.GET_USERS_SUCCESS, users: users.data });
    // console.log(users.data);
  } catch (e) {
    console.log(e);
  }
}

export default [
  takeEvery(Actions.USER_SIGNUP, signup),
  takeEvery(Actions.DELETE_USER, deleteUser),
  takeEvery(Actions.LOGIN_USER, login),
  takeEvery(Actions.VERIFY_USER, verify),
  takeEvery(Actions.UPDATE_USER_PASSWORD, updateUserPassword),
  takeEvery(Actions.CREATE_USER, createUser),
  takeEvery(Actions.GET_USERS, getUsers),
];
