import axios from "axios";
// import config from "../config/config";
import qs from "qs";
export * from './apiSuper';

// Client portal requests
const uServer = axios.create({
  // baseURL: config.baseURLUser,
  baseURL: process.env.REACT_APP_BASEURL,
});

uServer.interceptors.request.use(async (config) => {
  let accessToken = "";
  var token = window.localStorage.getItem("token");
  try {
    const t = JSON.parse(token);
    accessToken = t.token;
  } catch (error) {
    console.log(error);
  }

  config.headers["Authorization"] = `Bearer ${accessToken}`;

  config.headers["Access-Control-Allow-Headers"] = "*";
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.withCredentials = false;

  return config;
});

uServer.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const curPath = window.location.pathname;
  if (curPath != '/auth/login') {
    if (error?.response?.status == 401) {
      window.location.href = '/auth/login'
    }
  }
  return Promise.reject(error);
});


//User API calls
export const login = (userDetails) =>
  uServer.post("/login", userDetails);
export const signup = (userDetails) => uServer.post("/register", userDetails);
export const verify = (data) => uServer.post("/login/verify", data);
export const updateUserPassword = (data) => uServer.patch("/user", data);
export const getUserDetails = () => uServer.get("/user/details", {});
export const deleteUser = (userId) => uServer.delete(`/user/member/${userId}`);

export const createRole = (data) => uServer.post("/role/create", data);
export const editRole = ({ data, roleId }) =>
  uServer.post(`/role/${roleId}/update`, data);
export const deleteRole = (roleId) => uServer.delete(`/role/${roleId}`);
export const getCompanyRoles = (companyId) =>
  uServer.get(`/role/company/${companyId}`);
export const createUser = (userDetails) =>
  uServer.post("/user/create-member", userDetails);
export const getUsers = () => uServer.get("user/members");
export const getBranches = () => uServer.get("company/branches");

//RESERVATION API calls
export const getAllReservations = () => uServer.get("/reservation");

// Packs API calls
export const createPack = (data) => uServer.post("/pack", data);
export const activatePacks = (data) => uServer.post("/pack/batch-activate", data);
export const publishPacks = (data) => uServer.post("/pack/batch-publish", data);
export const fetchPacks = () => uServer.get("/pack");
export const deletePack = (id) => uServer.delete(`/pack/${id}`);
export const deletePacks = (data) => uServer.post("/pack/batch-delete", data);
export const sharePacks = (data) => uServer.post("/pack/batch-share", data);

export const fetchVouchers = (id) => uServer.get(`/pack/${id}`);

// Products API calls
export const createProduct = (data) => uServer.post("/product", data);
export const fetchProducts = () => uServer.get("/product");

// Voucher configuration
export const createVoucherConfig = (data) => uServer.post("/voucher-config", data);
export const updateVoucherConfig = ({ data, id }) => uServer.put(`/voucher-config/${id}`, data);
export const fetchVoucherConfig = () => uServer.get("/voucher-config");
export const deleteVoucherConfig = (id) => uServer.delete(`/voucher-config/${id}`);

// General / Common / Shared data
export const fetchCommon = () => uServer.get("/common");
