export function maskCard(cardNumber) {
  return `${"*".repeat(cardNumber.length - 4)}${cardNumber.substr(
    cardNumber.length - 4
  )}`;
}

export function validatePhoneNumber(phoneNumber) {
  const regex = /^\+?(233|0)\d{9}$/;
  if (!regex.test(phoneNumber)) return false;
  return "233" + phoneNumber.substring(phoneNumber.length - 9);
}

export const validPhoneNumberPattern = /^\+?(233|0)\d{9}$/g;

export const formatNumber = (number, decimalPlaces = 0) => {
  const numberFloat = number ? parseFloat(number) : 0;
  return numberFloat.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
  });
};

export const formatTransactionID = (id) => {
  if (!id) return "";
  return id.toString().padStart(6, "0");
};

export const displayMerchant = (redemption) => {
  if (redemption.type === "merchant")
    return redemption.Organization?.MerchantCode?.code || "N/A";
  return redemption.recipient || "N/A";
};

export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // textarea method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export const formatDate = (dateStr, isLong = true) => {
  const date = new Date(dateStr);
  const fullOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const shortOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const options = isLong ? fullOptions : shortOptions;
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export const fileResponseHandler = async (response) => {
  let hiddenElement = document.createElement("a");
  let url = window.URL || window.webkitURL;
  let blobPDF = url.createObjectURL(await response.blob());
  hiddenElement.href = blobPDF;
  hiddenElement.target = "_blank";
  hiddenElement.download = `report_${Date.now()}`;
  hiddenElement.click();
  return { data: null };
}

