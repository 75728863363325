export const CREATE_PACK = "[PACK] CREATE PACK";
export const CREATE_PACK_SUCCESS = "[PACK] CREATE PACK SUCCESS";
export const FETCH_PACKS = "[PACK] FETCH PACKS";
export const FETCH_PACKS_SUCCESS = "[PACK] FETCH PACKS SUCCESS";
export const DELETE_PACK = "[PACK] DELETE PACK";
export const DELETE_PACK_SUCCESS = "[PACK] DELETE PACK SUCCESS";
export const DELETE_PACKS = "[PACK] DELETE PACKS";
export const DELETE_PACKS_SUCCESS = "[PACK] DELETE PACKS SUCCESS";
export const FETCH_VOUCHERS = "[PACK] FETCH VOUCHERS";
export const FETCH_VOUCHERS_SUCCESS = "[PACK] FETCH VOUCHERS SUCCESS";
export const ACTIVATE_PACKS = "[PACK] ACTIVATE PACKS";
export const PUBLISH_PACKS = "[PACK] PUBLISH PACKS";
export const SHARE_PACKS = "[PACK] SHARE PACKS";

export const createPack = (payload) => (dispatch) =>
  dispatch({ type: CREATE_PACK, payload });

export const fetchPacks = (payload) => (dispatch) =>
  dispatch({ type: FETCH_PACKS, payload });

export const fetchVouchers = (payload) => (dispatch) =>
  dispatch({ type: FETCH_VOUCHERS, payload });

export const deletePack = (payload) => (dispatch) =>
  dispatch({ type: DELETE_PACK, payload });

export const deletePacks = (payload) => (dispatch) =>
  dispatch({ type: DELETE_PACKS, payload });

export const activatePacks = (payload) => (dispatch) =>
  dispatch({ type: ACTIVATE_PACKS, payload });

export const publishPacks = (payload) => (dispatch) =>
  dispatch({ type: PUBLISH_PACKS, payload });

export const sharePacks = (payload) => (dispatch) =>
  dispatch({ type: SHARE_PACKS, payload });