export const CREATE_PRODUCT = "[PRODUCT] CREATE PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "[PRODUCT] CREATE PRODUCT SUCCESS";
export const FETCH_PRODUCTS = "[PRODUCT] FETCH PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "[PRODUCT] FETCH PRODUCTS SUCCESS";

export const createProduct = (payload) => (dispatch) =>
  dispatch({ type: CREATE_PRODUCT, payload });

export const fetchProducts = (payload) => (dispatch) =>
  dispatch({ type: FETCH_PRODUCTS, payload });

