import * as Actions from "store/actions";

let initialState = {
  isLoading: false,
  data: [],
};

const merchant = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_MERCHANTS: 
      return { ...state, isLoading: true }

    case Actions.GET_MERCHANTS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload || state.data }

    default:
      return state;
  }
};

export default merchant;
