import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useGetVoucherAmountPayableMutation } from "services/pub";
import { useBuyVoucherMutation } from "services/pub";
import { formatNumber } from "utils/helper";
import { BreadcrumbsCustom } from "views/redemption-portal/common";
import { ButtonGeneric } from "views/redemption-portal/common";

const BuyUGift = () => {
  const history = useHistory();
  const { merchantcode } = useParams();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [senderNumber, setSenderNumber] = useState();
  const [buyVoucherRequest, { isLoading: isProcessingPayment }] =
    useBuyVoucherMutation();
  const [
    getVoucherAmountPayableRequest,
    { isLoading: isLoadingAmountPayable },
  ] = useGetVoucherAmountPayableMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { watch, control: controlPayment, handleSubmit: handleSubmitPayment } =
    useForm();

  const onContinueButtonClick = handleSubmit((data) => {
    getVoucherAmountPayableRequest({ voucherValue: data.voucherValue })
      .unwrap()
      .then((resp) => {
        console.log(resp);
        const { amountPayable } = resp;
        setFormData({ ...data, amountPayable });
        setStep(1);
      })
      .catch((e) => console.log(e));
  });

  const onConfirmButtonClick = () => {
    setStep(2);
  };

  const onBuyVoucherFormSubmit = handleSubmitPayment((data) => {
    const reqData = {
      ...formData,
      ...data,
      merchantCode: merchantcode
    };
    buyVoucherRequest(reqData)
      .unwrap()
      .then((response) => {
        if (data.paymentMethod === "card") {
          const { response: { paymentLink: { url } } } = response;
          // window.open(url, "_blank");
          window.location.href = url;
        }
        setSenderNumber(data.sender);
        setStep(3);
      })
      .catch((e) => console.log(e));
  });

  return (
    <>
      <form onSubmit={onContinueButtonClick}>
        <Box sx={{ display: step != 0 ? "none" : "block" }}>
          <Controller
            name="voucherValue"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl
                error={errors.voucherValue ? true : false}
                fullWidth
                sx={{ mb: 2 }}
              >
                <FormLabel sx={{ fontSize: "13px" }}>Enter amount</FormLabel>
                <OutlinedInput
                  type="number"
                  error={errors.voucherValue ? true : false}
                  {...field}
                  sx={{ outline: "none" }}
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">GHS</InputAdornment>
                  }
                />
              </FormControl>
            )}
          />

          <Controller
            name="recipient"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl
                error={errors.recipient ? true : false}
                fullWidth
                sx={{ mb: 2 }}
              >
                <FormLabel sx={{ fontSize: "13px" }}>
                  Enter Recipient Phone Number
                </FormLabel>
                <TextField error={errors.recipient ? true : false} {...field} />
              </FormControl>
            )}
          />

          <Controller
            name="personalizedMessage"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel sx={{ fontSize: "13px" }}>
                  (Optional) Enter personalized message to recipient
                </FormLabel>
                <TextField {...field} multiline />
              </FormControl>
            )}
          />
          <ButtonGeneric
            label={isLoadingAmountPayable ? "Processing..." : "Continue"}
            disabled={isLoadingAmountPayable}
            onClick={onContinueButtonClick}
          />
        </Box>
      </form>

      <Box sx={{ display: step != 1 ? "none" : "block" }}>
        <Typography variant="h6">Confirm purchase</Typography>
        <Divider sx={{ mb: 2 }} />
        <div className="mb-4">
          <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
            Recipient phone number
          </p>
          <p className="text-md font-bold">{formData?.recipient}</p>
        </div>
        <div className="mb-4">
          <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
            Voucher value
          </p>
          <p className="text-md font-bold">GHS {formData?.voucherValue}</p>
        </div>
        <div className="mb-4">
          <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
            Fee
          </p>
          <p className="text-md font-bold">
            GHS {formatNumber(formData?.amountPayable - formData?.voucherValue)}
          </p>
        </div>
        <div className="mb-4">
          <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
            Amount payable
          </p>
          <p className="text-md font-bold">GHS {formData?.amountPayable}</p>
        </div>

        {formData?.personalizedMessage &&
          formData?.personalizedMessage?.trim() !== "" && (
            <div className="mb-4">
              <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
                Short message to recipient
              </p>
              <p className="text-md font-bold">
                {formData?.personalizedMessage}
              </p>
            </div>
          )}
        <Button
          onClick={() => setStep(0)}
          variant="outlined"
          startIcon={<Edit />}
        >
          Edit
        </Button>
        <ButtonGeneric label="Confirm" onClick={onConfirmButtonClick} />
      </Box>

      <form onSubmit={onBuyVoucherFormSubmit}>
        <Box sx={{ display: step != 2 ? "none" : "block" }}>
          <Typography variant="h6">Pay</Typography>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Payment method
            </FormLabel>
            <Controller
              name="paymentMethod"
              control={controlPayment}
              defaultValue={"momo"}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="momo"
                    control={<Radio />}
                    label="Mobile money"
                  />
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label="Card"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {
            watch("paymentMethod") === "momo" &&
            <>
              <Controller
                name="network"
                control={controlPayment}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 120, mb: 2 }} size="small">
                    <InputLabel>Network</InputLabel>
                    <Select
                      {...field}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Network"
                    >
                      <MenuItem value={"AirtelTigo"}>AirtelTigo</MenuItem>
                      <MenuItem value={"MTN"}>MTN</MenuItem>
                      <MenuItem value={"Vodafone"}>Vodafone</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                name="sender"
                control={controlPayment}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <FormLabel>Phone number</FormLabel>
                    <TextField {...field} />
                  </FormControl>
                )}
              />
            </>
          }

          <ButtonGeneric
            label={isProcessingPayment ? "Processing" : "Buy voucher"}
            disabled={isProcessingPayment}
            onClick={onBuyVoucherFormSubmit}
          />
        </Box>
      </form>

      <Box sx={{ display: step != 3 ? "none" : "block" }}>
        {
          watch("paymentMethod") === "card" ?
            <Typography sx={{ mb: 2 }}>
              Please wait while you're redirected to the card processing page.
            </Typography>
            :
            <>
              <Typography sx={{ mb: 2 }}>
                Dial *161*600*171# on the phone number ({senderNumber}) and approve
                this purchase to receive the payment prompt.
              </Typography>

              <ButtonGeneric
                label={"Done"}
                onClick={() => history.push(`/redemption/${merchantcode}`)}
              />
            </>
        }
      </Box>
    </>
  );
};

export default BuyUGift;
