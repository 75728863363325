import ReCAPTCHA from "react-google-recaptcha";
import { BreadcrumbsCustom } from "views/redemption-portal/common";
import { ButtonGeneric } from "views/redemption-portal/common";
import VerificationPage from "../verification/Verification";
import useRedeem from "./hooks/useRedeem";

const { TextInputWithToggle } = require("views/redemption-portal/common");

const RedeemPage = ({ setVoucherData }) => {
  const {
    handleVoucherCodeFormSubmission,
    captchaRef,
    isValidVoucherCode,
    merchantcode,
    Controller,
    control,
    isLoading,
    errorMessage,
  } = useRedeem(setVoucherData);

  return (
    <div>
      <BreadcrumbsCustom
        breadcrumbs={[
          { label: "Home", link: `/redemption/${merchantcode}` },
          { label: "Redeem Voucher" },
        ]}
      />
      {!isValidVoucherCode ? (
        <div>
          <form onSubmit={handleVoucherCodeFormSubmission}>
            <p className="text-sm font-bold">Enter voucher code</p>
            <Controller
              name="voucherCode"
              rules={{ required: true }}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInputWithToggle disabled={isLoading} {...field} />
              )}
            />

            {errorMessage && (
              <p style={{ color: "red" }} className="mt-2 text-center text-sm">
                {errorMessage}
              </p>
            )}

            <div className="mt-4">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />
            </div>

            <ButtonGeneric
              onClick={handleVoucherCodeFormSubmission}
              label={isLoading ? "Checking..." : "Redeem Voucher"}
              disabled={isLoading}
            />
          </form>
        </div>
      ) : (
        <VerificationPage />
      )}
    </div>
  );
};

export default RedeemPage;
