var initialState = {
    success: true,
    message: 'You are notified', 
    open: false,
}

const notification = (state = initialState, action) => {
    switch(action.type){
		case 'SHOW_NOTIFICATION':
			return {...state, open: true, message: action.message, success: action.success};
		case 'HIDE_NOTIFICATION':
			return {...state, open: false}
		default:
			return state;
    }
}

export default notification