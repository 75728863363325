import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";

function* fetchCommon() {
  try {
    const { data: { industries, regions, merchants, commission, financialInstitutions } } = yield call(API.fetchCommon);
    yield put({ type: Actions.FETCH_COMMON_SUCCESS, regions, industries, merchants, commission, financialInstitutions })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to retrieve merchants, industry and region data. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

export default [
  takeEvery(Actions.FETCH_COMMON, fetchCommon),
];
