import { Button, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router";
import { ButtonGeneric } from "views/redemption-portal/common";

const VerificationForm = ({
  verificationMethod,
  changeVerificationMethod,
  handleVerification,
  isLoading,
  errorMessage,
}) => {
  const { handleSubmit, control } = useForm();
  const handleFormSubmit = handleSubmit(({ verificationCode }) =>
    handleVerification(verificationCode)
  );
  return (
    <div>
      <p className="font-bold mb-4" style={{ color: "#EC5F33" }}>
        {verificationMethod === "otp"
          ? "OTP verification"
          : "USSD verification"}
      </p>
      <div className="flex items-center text-sm font-bold">
        Enter code
        <Button
          onClick={changeVerificationMethod}
          size="small"
          sx={{ textTransform: "capitalize" }}
        >
          Change verification method
        </Button>
      </div>
      <form onSubmit={handleFormSubmit}>
        <Controller
          name="verificationCode"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              disabled={isLoading}
              {...field}
              type="number"
              pattern="[0-9]*"
              variant="outlined"
              fullWidth
            />
          )}
        />

        {errorMessage && (
          <p style={{ color: "red" }} className="mt-2 text-center text-sm">
            {errorMessage}
          </p>
        )}

        <ButtonGeneric
          disbled={isLoading}
          label="Verify"
          onClick={handleFormSubmit}
        />
      </form>
    </div>
  );
};

export default VerificationForm;
