import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export default function PageBreadcrumbs({ breadcrumbs: breadcrumbsArr }) {
  const breadcrumbs = breadcrumbsArr?.map(({ label, link }) => {
    return link ? (
      <Typography color="inherit">
        <Link to={link}>{label}</Link>
      </Typography>
    ) : (
      <Typography key="3" color="text.primary">
        {label}
      </Typography>
    );
  });

  return (
    <Stack spacing={2} mb={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
