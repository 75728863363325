import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";

function* createPack({ payload: { data, onComplete } }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    const { data: packData } = yield call(API.createPack, data);
    const updatedPacks = [...packData, ...packs ]
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })

    onComplete(true);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack successfully created!',
      success: true,
    });

  } catch (e) {
    onComplete(false);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to create pack. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* fetchPacks() {
  try {
    const { data } = yield call(API.fetchPacks);
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: data })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to fetch packs. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* fetchVouchers({ payload: id }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    const { data: pack } = yield call(API.fetchVouchers, id);

    const updatedPacks = packs.filter(({ id }) => id == pack.id).length > 0 ? packs.map((packUnit => {
      return packUnit.id == pack.id ? pack : packUnit
    })) : [...packs, pack]
    console.log(updatedPacks);
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to fetch vouchers. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* deletePack({ payload: id }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    yield call(API.deletePack, id);
    const updatedPacks = packs.filter(packUnit => packUnit.id != id)
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack successfully deleted!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to delete pack. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* deletePacks({ payload: ids }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    const { data: { deletedIds } } = yield call(API.deletePacks, { ids });
    const updatedPacks = packs.filter(packUnit => !deletedIds.includes(packUnit.id))
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack(s) successfully deleted!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to delete pack(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* activatePacks({ payload: ids }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    const { data: { activatedIds } } = yield call(API.activatePacks, { ids });
    const updatedPacks = packs.map(packUnit => activatedIds.includes(packUnit.id) ? ({ ...packUnit, isActive: 1 }) : packUnit)
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack(s) successfully activated!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate pack(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* publishPacks({ payload: ids }) {
  try {
    let { packs } = yield select(({ pack }) => pack);
    const { data: { publishedIds } } = yield call(API.publishPacks, { ids });
    const updatedPacks = packs.map(packUnit => publishedIds.includes(packUnit.id) ? ({ ...packUnit, isActive: 2 }) : packUnit)
    yield put({ type: Actions.FETCH_PACKS_SUCCESS, packs: updatedPacks })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack(s) successfully published!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to publish pack(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* sharePacks({ payload: data }) {
  try {
    yield call(API.sharePacks, data);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Pack(s) successfully shared!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to publish pack(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

export default [
  takeEvery(Actions.CREATE_PACK, createPack),
  takeEvery(Actions.FETCH_PACKS, fetchPacks),
  takeEvery(Actions.FETCH_VOUCHERS, fetchVouchers),
  takeEvery(Actions.DELETE_PACK, deletePack),
  takeEvery(Actions.DELETE_PACKS, deletePacks),
  takeEvery(Actions.ACTIVATE_PACKS, activatePacks),
  takeEvery(Actions.PUBLISH_PACKS, publishPacks),
  takeEvery(Actions.SHARE_PACKS,sharePacks),
];
