import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { useGetMerchantByCodeQuery } from "services/pub";

const useHome = () => {
  let { path, url } = useRouteMatch();
  const { merchantcode } = useParams();
  const { isLoading, error, isSuccess, isError, data } =
    useGetMerchantByCodeQuery(merchantcode);
  const { merchant } = data || {};
  const { Organization, code: merchantCode } = merchant || {};
  const { name: merchantName } = Organization || {};
  const [voucherData, setVoucherData] = useState(null);

  return {
    merchantcode,
    path,
    url,
    isLoading,
    isError,
    data,
    Organization,
    merchantName,
    merchantCode,
    error,
    voucherData,
    setVoucherData,
  };
};

export default useHome;
