import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const pub = createApi({
  reducerPath: "pub",
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
    tagTypes: [],
  }),
  endpoints: (builder) => ({
    getMerchantByCode: builder.query({
      query: (code) => `pub/merchant/${code}`,
    }),
    validateVoucher: builder.mutation({
      query: (body) => ({
        url: "pub/voucher",
        method: "POST",
        body,
      }),
    }),
    requestVoucherVerificationCode: builder.mutation({
      query: (body) => ({
        url: "pub/sendcode",
        method: "POST",
        body,
      }),
    }),
    sendVoucherVerificationCode: builder.mutation({
      query: (body) => ({
        url: "pub/verify-voucher-code",
        method: "POST",
        body,
      }),
    }),
    redeemMerchant: builder.mutation({
      query: (body) => ({
        url: "pub/merchant/redeem",
        method: "POST",
        body,
      }),
    }),
    buyVoucher: builder.mutation({
      query: (body) => ({
        url: "pub/buy-voucher",
        method: "POST",
        body,
      }),
    }),
    getVoucherAmountPayable: builder.mutation({
      query: (body) => ({
        url: "pub/voucher-amount-payable",
        method: "POST",
        body,
      }),
    }),
    getAllMerchantOffers: builder.query({
      query: (id) => `pub/merchant-offers`,
    }),
    getMerchantOffers: builder.query({
      query: (id) => `pub/merchant-offers/${id}`,
    }),
  }),
});

export const {
  useGetMerchantByCodeQuery,
  useValidateVoucherMutation,
  useRequestVoucherVerificationCodeMutation,
  useSendVoucherVerificationCodeMutation,
  useRedeemMerchantMutation,
  useBuyVoucherMutation,
  useGetVoucherAmountPayableMutation,
  useGetMerchantOffersQuery,
  useGetAllMerchantOffersQuery,
} = pub;
