import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import store from "./store";
import { ConfirmProvider } from "material-ui-confirm";

// views without layouts

// import Landing from "views/Landing.js";
// import Index from "views/Index.js";
import Notification from "components/Notification/Notification.js";
import { Provider } from "react-redux";
// import { createStore, applyMiddleware } from "redux";

// layouts
import { RedemptionPortalHome } from "views/redemption-portal";
import Fallback from "components/Fallback/Fallback";
const Super = lazy(() => import("layouts/Super.js"));
const Auth = lazy(() => import("layouts/Auth.js"));
const Profile = lazy(() => import("views/Profile.js"));
const Admin = lazy(() => import("layouts/Admin.js"));

const theme = createTheme({
  palette: {
    secondary: {
      light: "#f26423",
      main: "#f26423",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#f26423",
    },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans",
      "Roboto",
      "Proxima Nova",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Fallback />}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <Notification />
            <Switch>
              {/* add routes with layouts */}
              <Route path="/super" component={Super} />
              <Route path="/admin" component={Admin} />
              <Route path="/auth" component={Auth} />
              <Route
                path="/redemption/:merchantcode"
                component={RedemptionPortalHome}
              />
              <Route path="/profile" exact component={Profile} />
              {/* add redirect for first page */}
              <Redirect from="/" to="/admin" />
              <Redirect from="*" to="/" />
            </Switch>
          </ConfirmProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
