import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fileResponseHandler } from "utils/helper";

export const superApi = createApi({
  reducerPath: "superApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURLSUPER,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().superAdmin?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "MerchantCodeGroup",
    "Redemptions",
    "VoucherCount",
    "ServiceCommission",
    "Adminusers",
    "ServiceFees",
    "Agents",
    "Settlements",
    "Users",
  ],
  endpoints: (builder) => ({
    getMerchantCode: builder.query({
      query: () => `merchant-code`,
      providesTags: ["MerchantCodeGroup"],
    }),
    exportMerchantCodes: builder.query({
      query: (groupId) => `merchant-code/export/${groupId}`,
    }),
    generateMerchantCodes: builder.mutation({
      query: (body) => ({
        url: "merchant-code/generate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["MerchantCodeGroup"],
    }),
    getVoucherCount: builder.query({
      query: () => "voucher/count",
      providesTags: ["VoucherCount"],
    }),
    uploadVoucherCSV: builder.mutation({
      query: (body) => ({
        url: "voucher/import",
        method: "POST",
        body,
      }),
    }),
    getRedemptions: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          page = 1,
          format,
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (format) queryParams.format = format;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `voucher/redemptions${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
      providesTags: ["Redemptions"],
    }),
    generateReport: builder.mutation({
      query: (body) => ({
        url: "/report",
        method: "POST",
        body,
        responseHandler: fileResponseHandler,
        cache: "no-cache",
      }),
    }),
    getRedemptionDetails: builder.query({
      query: (id) => `voucher/redemptions/${id}`,
    }),
    getDashboardStats: builder.query({
      query: () => "dashboard",
    }),
    assignUSDDvendor: builder.mutation({
      query: (body) => ({
        url: "config/assign-vendor",
        method: "POST",
        body,
      }),
    }),
    getServiceCommissions: builder.query({
      query: () => "service",
      providesTags: ["ServiceCommission"],
    }),
    getServiceFees: builder.query({
      query: () => "service/fees",
      providesTags: ["ServiceFees"],
    }),
    updateServiceFees: builder.mutation({
      query: (body) => ({
        url: `service/fees/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ServiceFees"],
    }),
    updateServiceCommissions: builder.mutation({
      query: ({ data: body, id }) => ({
        url: `service/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ServiceCommission"],
    }),
    getAdminUsers: builder.query({
      query: () => "user",
      providesTags: ["Adminusers"],
    }),
    addAdminUser: builder.mutation({
      query: (body) => ({
        url: "user",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Adminusers"],
    }),
    deleteAdminUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Adminusers"],
    }),
    updateAdminUser: builder.mutation({
      query: ({ id, data: body }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Adminusers"],
    }),
    getRevenues: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          search,
          status,
          page = 1,
          format,
          serviceName
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (search) queryParams.search = search;
        if (status) queryParams.status = status;
        if (format) queryParams.format = format;
        if (serviceName) queryParams.serviceName = serviceName;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `revenue${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
    }),
    getRevenueDetails: builder.query({
      query: (id) => `revenue/${id}`,
    }),
    getAgents: builder.query({
      query: () => "organization/agent",
      providesTags: ["Agents"],
    }),
    changeSubscriptionType: builder.mutation({
      query: (body) => ({
        url: "organization/batch-subscription-change",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Agents", "Clients", "Merchants"],
    }),
    activeOrganization: builder.mutation({
      query: (body) => ({
        url: "organization/batch-activate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Agents", "Clients", "Merchants"],
    }),
    deactiveOrganization: builder.mutation({
      query: (body) => ({
        url: "organization/batch-deactivate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Agents", "Clients", "Merchants"],
    }),
    getMerchantSettlementOption: builder.query({
      query: (id) => `/settlement-option/${id}`,
    }),
    updateMerchantSettlementOption: builder.mutation({
      query: (body) => ({
        url: `/settlement-option`,
        body,
        method: "POST",
      }),
    }),
    getDefaultSettlementOption: builder.query({
      query: () => `/config/settlement-option`,
    }),
    updateDefaultSettlementConfig: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/config/settlement-option/${id}`,
        body: rest,
        method: "PUT",
      }),
    }),
    getDefaultPaymentGateway: builder.query({
      query: () => `/config/payment-gateway`,
    }),

    updatePaymentGatewayConfig: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/config/payment-gateway/${id}`,
        body: rest,
        method: "PUT",
      }),
    }),
    getVoucherExpiryConfig: builder.query({
      query: () => `/config/voucher-expiry`,
    }),

    updateVoucherExpiryConfig: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/config/voucher-expiry`,
        body: rest,
        method: "PUT",
      }),
    }),
    getMinRedeemableAmountConfig: builder.query({
      query: () => `/config/min-redeemable-amount`,
    }),

    updateMinRedeemableAmountConfig: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/config/min-redeemable-amount`,
        body: rest,
        method: "PUT",
      }),
    }),
    getSettlements: builder.query({
      query: ({ settlementOption }) => `/settlement/${settlementOption}`,
      providesTags: ["Settlements"],
    }),
    processSettlement: builder.mutation({
      query: (body) => ({
        url: "/settlement/process",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Settlements"],
    }),
    updateOrganizationUser: builder.mutation({
      query: ({ userId, ...rest }) => ({
        url: `/organization/user/${userId}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Users"],
    }),
    getVoucherPurchases: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          search,
          source,
          transactionStatus,
          page = 1,
          format,
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (search) queryParams.search = search;
        if (source) queryParams.source = source;
        if (transactionStatus) queryParams.transactionStatus = transactionStatus;
        if (format) queryParams.format = format;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `voucher/purchases${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
    }),
    getVoucherPurchaseDetails: builder.query({
      query: (id) => `/voucher/purchase/${id}`,
    }),
    getVoucherGenerationTransactions: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          search,
          status,
          usage,
          page = 1,
          format,
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (search) queryParams.search = search;
        if (status) queryParams.status = status;
        // if (usage) queryParams.usage = usage;
        if (format) queryParams.format = format;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `voucher/generations${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
    }),
    getVoucherDetails: builder.query({
      query: (id) => `voucher/${id}`,
    }),
    getSettlementDetails: builder.query({
      query: (id) => `settlement/details/${id}`,
    }),
    getOrganizationFinancials: builder.query({
      query: (id) => `merchant-financial-account/${id}`,
    }),
    updateOrganizationFinancials: builder.mutation({
      query: (body) => ({
        url: `merchant-financial-account`,
        method: "POST",
        body,
      }),
    }),
    getOrganizationCommission: builder.query({
      query: ({ id, commissionType }) => `organization/${id}/type/${commissionType}`,
    }),
    updateOrganizationCommission: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `organization/${id}/commission`,
        method: "PUT",
        body: rest,
      })
    })
  }),
});

export const {
  useGetMerchantCodeQuery,
  useGenerateMerchantCodesMutation,
  useGetVoucherCountQuery,
  useGetRedemptionsQuery,
  useLazyGetRedemptionsQuery,
  useGetRedemptionDetailsQuery,
  useUploadVoucherCSVMutation,
  useGetDashboardStatsQuery,
  useAssignUSDDvendorMutation,
  useGenerateReportMutation,
  useGetServiceCommissionsQuery,
  useUpdateServiceCommissionsMutation,
  useGetAdminUsersQuery,
  useAddAdminUserMutation,
  useDeleteAdminUserMutation,
  useUpdateAdminUserMutation,
  useGetRevenuesQuery,
  useLazyGetRevenuesQuery,
  useGetServiceFeesQuery,
  useUpdateServiceFeesMutation,
  useGetAgentsQuery,
  useChangeSubscriptionTypeMutation,
  useActiveOrganizationMutation,
  useDeactiveOrganizationMutation,
  useGetRevenueDetailsQuery,
  useExportMerchantCodesQuery,
  useGetMerchantSettlementOptionQuery,
  useUpdateMerchantSettlementOptionMutation,
  useGetDefaultSettlementOptionQuery,
  useUpdateDefaultSettlementConfigMutation,
  useGetDefaultPaymentGatewayQuery,
  useUpdatePaymentGatewayConfigMutation,
  useGetSettlementsQuery,
  useProcessSettlementMutation,
  useUpdateOrganizationUserMutation,
  useGetVoucherPurchasesQuery,
  useLazyGetVoucherPurchasesQuery,
  useGetVoucherPurchaseDetailsQuery,
  useGetVoucherGenerationTransactionsQuery,
  useLazyGetVoucherGenerationTransactionsQuery,
  useGetVoucherDetailsQuery,
  useGetSettlementDetailsQuery,
  useGetOrganizationFinancialsQuery,
  useUpdateOrganizationFinancialsMutation,
  useGetOrganizationCommissionQuery,
  useUpdateOrganizationCommissionMutation,
  useGetVoucherExpiryConfigQuery,
  useUpdateVoucherExpiryConfigMutation,
  useGetMinRedeemableAmountConfigQuery,
  useUpdateMinRedeemableAmountConfigMutation,
} = superApi;
