export const USER_SIGNUP = "[USER] SIGNUP";
export const DELETE_USER = "[USER] DELETE USER";
export const DELETE_USER_SUCCESS = "[USER] DELETE USER SUCCESS";
export const LOGIN_USER = "[USER] LOGIN";
export const VERIFY_USER = "[USER] VERIFY USER";
export const VERIFY_USER_SUCCESS = "[USER] VERIFY USER SUCCESS";
export const UPDATE_USER_PASSWORD = "[USER] UPDATE USER PASSWORD";
export const CREATE_USER = "[USER] CREATE USER";
export const CREATE_USER_SUCCESS = "[USER] CREATE USERSUCCESS";
export const GET_USERS_SUCCESS = "[USER] GET USERS SUCCESS";
export const GET_USERS = "[USER] GET USERS";

export const login = (payload) => (dispatch) =>
  dispatch({ type: LOGIN_USER, payload });
export const verifyUser = (payload) => (dispatch) =>
  dispatch({ type: VERIFY_USER, payload });
export const updatePassword = (payload) => (dispatch) =>
  dispatch({ type: UPDATE_USER_PASSWORD, payload });

export const createUser = (payload) => (dispatch) =>
  dispatch({ type: CREATE_USER, payload });
export const getUsers = (payload) => (dispatch) =>
  dispatch({ type: GET_USERS, payload });
export const signup = (payload) => (dispatch) =>
  dispatch({ type: USER_SIGNUP, payload });
export const deleteUser = (payload) => (dispatch) =>
  dispatch({ type: DELETE_USER, payload });
