import * as Actions from "store/actions";

var initialState = {
  voucherConfigs: [],
};

const voucherConfig = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_VOUCHER_CONFIG_SUCCESS:
      return Object.assign({}, state, { voucherConfigs: action.voucherConfigs });
    
    default:
      return state;
  }
};

export default voucherConfig;
