import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";
import jwt_decode from "jwt-decode";

function setToken(data) {
  window.localStorage.setItem("superAdminToken", JSON.stringify(data));
}

function* login({ payload: { userDetails, history, onComplete } }) {
  try {
    yield put({ type: Actions.LOGIN_SUPER_ADMIN_START });
    const { data } = yield call(API.loginSuperAdmin, userDetails);
    const loginData = jwt_decode(data.token);
    yield put({ type: Actions.LOGIN_SUPER_ADMIN_SUCCESS, payload: loginData });
    yield put({ type: Actions.SET_SUPER_ADMIN_TOKEN, token: data.token })
    yield call(setToken, { token: data.token });
    history.push("/super/dashboard");
  } catch (e) {
    onComplete({ success: false, message: e?.response?.data?.message });
    console.log(e);
  }
}

function waitSmall() {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve({ book: 'ready' }), 2000);
  })
}

function* getSystemConfig() {
  try {
    yield put({ type: Actions.LOGIN_SUPER_ADMIN_START });
    const { data } = yield call(API.getSystemConfig);
    // yield call(waitSmall);
    yield put({ type: Actions.GET_SYSTEM_CONFIG_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: Actions.GET_SYSTEM_CONFIG_FAIL })
    yield put({ type: 'SHOW_NOTIFCATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* deleteFinancialInstitutions({ payload: data }) {
  try {
    const financialInstitutionsData = yield select(({ superAdmin: { financialInstitutions: { data } } }) => data)
    yield call(API.deleteFinancialInstitutions, data);
    const updatedData = financialInstitutionsData.filter(fi => !data?.ids.includes(fi.id))
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Financial institutions deleted' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_FAIL })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* updateFinancialInstitution({ payload: { id, data } }) {
  try {
    const financialInstitutionsData = yield select(({ superAdmin: { financialInstitutions: { data } } }) => data)
    yield call(API.updateFinancialInstitution, { id, data });
    const updatedData = financialInstitutionsData.map(fi => {
      return id === fi.id ? { ...fi, name: data.name, type: data.type } : fi;
    })
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Financial institution updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_FAIL })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* addFinancialInstitutions({ payload }) {
  try {
    const financialInstitutionsData = yield select(({ superAdmin: { financialInstitutions: { data } } }) => data)
    const { data: { financialInstitution: addedData } } = yield call(API.addFinancialInstitutions, payload);
    const updatedData = [...financialInstitutionsData, ...addedData]
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Financial institution updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_FINANCIAL_INST_FAIL })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* addRegions({ payload }) {
  try {
    const regionsData = yield select(({ superAdmin: { regions: { data } } }) => data)
    const { data: { region: addedData } } = yield call(API.addRegions, payload);
    const updatedData = [...regionsData, ...addedData]
    yield put({ type: Actions.UPDATE_REGION_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Region updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_REGION_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* updateRegion({ payload: { id, data } }) {
  try {
    const regionsData = yield select(({ superAdmin: { regions: { data } } }) => data)
    yield call(API.updateRegion, { id, data });
    const updatedData = regionsData.map(region => {
      return id === region.id ? { ...region, name: data.name } : region;
    })
    yield put({ type: Actions.UPDATE_REGION_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Region updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_REGION_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* deleteRegions({ payload: data }) {
  try {
    const regionsData = yield select(({ superAdmin: { regions: { data } } }) => data)
    yield call(API.deleteRegions, data);
    const updatedData = regionsData.filter(region => !data?.ids.includes(region.id))
    yield put({ type: Actions.UPDATE_REGION_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Region(s) deleted' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_REGION_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* addIndustries({ payload }) {
  try {
    const industriesData = yield select(({ superAdmin: { industries: { data } } }) => data)
    const { data: { industry: addedData } } = yield call(API.addIndustries, payload);
    const updatedData = [...industriesData, ...addedData,]
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Industry updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* updateIndustry({ payload: { id, data } }) {
  try {
    const industriesData = yield select(({ superAdmin: { industries: { data } } }) => data)
    yield call(API.updateIndustry, { id, data });
    const updatedData = industriesData.map(industry => {
      return id === industry.id ? { ...industry, name: data.name } : industry;
    })
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Industry updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* deleteIndustries({ payload: data }) {
  try {
    const industriesData = yield select(({ superAdmin: { industries: { data } } }) => data)
    yield call(API.deleteIndustries, data);
    const updatedData = industriesData.filter(industry => !data?.ids.includes(industry.id))
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS, payload: updatedData })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'Industries deleted' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_INDUSTRY_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

function* updateGeneralConfig({ payload: data }) {
  try {
    const { data: { updatedConfigs } } = yield call(API.updateGeneralConfigs, data);
    yield put({ type: Actions.UPDATE_GENERAL_CONFIG_SUCCESS, payload: updatedConfigs })
    yield put({ type: 'SHOW_NOTIFICATION', success: true, message: 'General configuration updated' })

  } catch (e) {
    yield put({ type: Actions.UPDATE_GENERAL_CONFIG_SUCCESS })
    yield put({ type: 'SHOW_NOTIFICATION', success: false, message: e?.response.data?.message || 'An error occurred' })
    console.log(e);
  }
}

export default [
  takeEvery(Actions.LOGIN_SUPER_ADMIN, login),
  takeEvery(Actions.GET_SYSTEM_CONFIG, getSystemConfig),
  takeEvery(Actions.DELETE_FINANCIAL_INST, deleteFinancialInstitutions),
  takeEvery(Actions.UPDATE_FINANCIAL_INST, updateFinancialInstitution),
  takeEvery(Actions.ADD_FINANCIAL_INST, addFinancialInstitutions),
  takeEvery(Actions.ADD_REGION, addRegions),
  takeEvery(Actions.UPDATE_REGION, updateRegion),
  takeEvery(Actions.DELETE_REGION, deleteRegions),
  takeEvery(Actions.ADD_INDUSTRY, addIndustries),
  takeEvery(Actions.UPDATE_INDUSTRY, updateIndustry),
  takeEvery(Actions.DELETE_INDUSTRY, deleteIndustries),
  takeEvery(Actions.UPDATE_GENERAL_CONFIG, updateGeneralConfig),
];
