import { LinearProgress } from '@mui/material';

const Fallback = () => (
  <div style={{ height: "100vh" }} className="flex items-center justify-center">
    <span className="inline-block text-center" style={{ width: "200px" }}>
      <LinearProgress sx={{ color: "#f16423" }} />
    </span>
  </div>
);

export default Fallback;