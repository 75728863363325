import * as Actions from "store/actions";

let initialState = {
  isLoading: false,
  data: [],
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CLIENTS: case Actions.UPDATE_CLIENT_COMMISSION: case Actions.UPDATE_SETUP_FEE: 
      return { ...state, isLoading: true }

    case Actions.GET_CLIENTS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload || state.data }

    default:
      return state;
  }
};

export default client;
