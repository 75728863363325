import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { formatNumber } from "utils/helper";
import { ButtonGeneric } from "views/redemption-portal/common";
import TransactionResponse from "../transactionResponse/TransactionResponse";
import useRedemptionForm from "./hooks/useRedemptionForm";

const RedemptionForm = () => {
  const {
    transactionStatus,
    handleRedemptionFormSubmit,
    voucherAmountRemaining,
    Controller,
    control,
    errorMessage,
    isLoading,
    transactionResponse,
  } = useRedemptionForm();
  return (
    <>
      {!transactionStatus ? (
        <div>
          <div
            className="text-sm p-4 py-2 mb-4"
            style={{
              backgroundColor: "#FBDDD3",
              color: "#EC5F33",
              borderRadius: "10px",
            }}
          >
            Your voucher balance is{" "}
            <span className="font-bold">
              GHS{formatNumber(voucherAmountRemaining, 2)}
            </span>
            .
          </div>

          <form onSubmit={handleRedemptionFormSubmit}>
            <p className="text-xs font-bold">Enter amount</p>
            <Controller
              name="amountToRedeem"
              control={control}
              rules={{ required: true, min: 10, max: voucherAmountRemaining }}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth>
                  <OutlinedInput
                    type="number"
                    {...field}
                    sx={{ outline: "none" }}
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">GHS</InputAdornment>
                    }
                  />
                </FormControl>
              )}
            />
            {errorMessage && (
              <p style={{ color: "red" }} className="mt-2 text-center text-sm">
                {errorMessage}
              </p>
            )}

            {/* <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
            Full redemption
          </p>
          <p className="text-xl font-bold">GHS 150.00</p> */}

            <ButtonGeneric
              label={isLoading ? "Processing" : "Redeem"}
              disabled={isLoading}
              onClick={handleRedemptionFormSubmit}
            />
          </form>
        </div>
      ) : (
        <TransactionResponse
          transactionId={transactionResponse?.transactionId}
          amount={transactionResponse?.amount}
        />
      )}
    </>
  );
};

export default RedemptionForm;
