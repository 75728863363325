import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { fileResponseHandler } from "utils/helper";

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["api", "apiKeys", "apiPacks"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
    tagTypes: ["apiKeys", "apiPacks", "branch", "voucherConfigs"],
  }),
  endpoints: (builder) => ({
    verifyToken: builder.mutation({
      query: (body) => ({
        url: "verify/token",
        method: "POST",
        body,
      }),
    }),
    sendOTP: builder.mutation({
      query: (body) => ({
        url: "verify/send-otp",
        method: "POST",
        body,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (body) => ({
        url: "verify/otp",
        method: "POST",
        body,
      }),
    }),
    getAccountBalance: builder.query({
      query: () => "account-balance",
    }),
    getAPIKeys: builder.query({
      query: () => "apiKey",
      providesTags: ["apiKeys"],
    }),
    generateApiKeys: builder.mutation({
      query: () => ({
        url: "apiKey",
        method: "POST",
      }),
      invalidatesTags: ["apiKeys"],
    }),
    assignContactsToPack: builder.mutation({
      query: (body) => ({
        url: "pack/assign-contact-vouchers",
        method: "POST",
        body,
      }),
      invalidatesTags: ["apiPacks"],
    }),
    getApiPacks: builder.query({
      query: () => "pack/category/api",
      providesTags: ["apiPacks"],
    }),
    activatePack: builder.mutation({
      query: (body) => ({
        url: "pack/batch-activate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["apiPacks"],
    }),
    addBranch: builder.mutation({
      query: (body) => ({
        url: "branch",
        method: "POST",
        body,
      }),
      invalidatesTags: ["branch"],
    }),
    getBranches: builder.query({
      query: () => "branch",
      providesTags: ["branch"],
    }),
    loginAsBranch: builder.mutation({
      query: (body) => ({
        url: "branch/login",
        method: "POST",
        body,
      }),
    }),
    createSalesVoucherConfig: builder.mutation({
      query: (body) => ({
        url: "voucher-config/public",
        method: "POST",
        body,
      }),
      invalidatesTags: ["voucherConfigs"],
    }),
    getVoucherConfig: builder.query({
      query: (qs) => `voucher-config${qs ? qs : ""}`,
      providesTags: ["voucherConfigs"],
    }),
    getMerchantRedemptions: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          page = 1,
          format,
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (format) queryParams.format = format;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `redemption/merchant${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
    }),
    getFinancialAccount: builder.query({
      query: () => "financial-account",
    }),
    updatePublicOffer: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `voucher-config/offer/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["voucherConfigs"],
    }),
    deletePublicOffer: builder.mutation({
      query: ({ id }) => ({
        url: `voucher-config/offer/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["voucherConfigs"],
    }),
    getSettlements: builder.query({
      query: ({ page, limit, search }) => `settlement/merchant?page=${page}&limit=${limit}&search=${search || ""}`
    }),
    getVoucherPurchases: builder.query({
      query: (body) => {
        const {
          type,
          startDate,
          endDate,
          limit,
          search,
          source,
          transactionStatus,
          page = 1,
          format,
        } = body || {};

        const queryParams = {};

        if (type) queryParams.type = type;
        if (startDate) queryParams.startDate = startDate;
        if (endDate) queryParams.endDate = endDate;
        if (limit) queryParams.limit = limit;
        if (page) queryParams.page = page;
        if (search) queryParams.search = search;
        if (source) queryParams.source = source;
        if (transactionStatus) queryParams.transactionStatus = transactionStatus;
        if (format) queryParams.format = format;

        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        return {
          url: `voucher-purchase${queryString ? `?${queryString}` : ""}`,
          method: "GET",
          ...(format ? { responseHandler: fileResponseHandler, cache: "no-cache" } : {}),
        };
      },
    }),
    getDashboard: builder.query({
      query: ({ duration }) => ({
        url: `dashboard/?duration=${duration}`
      })
    }),
    getDashboardOverall: builder.query({
      query: () => ({
        url: `dashboard/overall`
      })
    }),
    uploadLogo: builder.mutation({
      query: (file) => ({
        url: '/profile/logo', // Your upload endpoint
        method: 'POST',
        body: file
      }),
    }),
    sellAgentVoucher: builder.mutation({
      query: (body) => ({
        url: '/voucher-purchase',
        method: 'POST',
        body
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: '/password-reset',
        method: 'POST',
        body
      })
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: '/password-reset/change-password',
        method: 'POST',
        body
      })
    }),
  }),
});

export const {
  useSendOTPMutation,
  useVerifyOTPMutation,
  useGetAccountBalanceQuery,
  useVerifyTokenMutation,
  useGetAPIKeysQuery,
  useGenerateApiKeysMutation,
  useAssignContactsToPackMutation,
  useGetApiPacksQuery,
  useActivatePackMutation,
  useAddBranchMutation,
  useGetBranchesQuery,
  useLoginAsBranchMutation,
  useCreateSalesVoucherConfigMutation,
  useGetVoucherConfigQuery,
  useGetMerchantRedemptionsQuery,
  useLazyGetMerchantRedemptionsQuery,
  useGetFinancialAccountQuery,
  useUpdatePublicOfferMutation,
  useDeletePublicOfferMutation,
  useGetSettlementsQuery,
  useGetVoucherPurchasesQuery,
  useLazyGetVoucherPurchasesQuery,
  useGetDashboardQuery,
  useUploadLogoMutation,
  useSellAgentVoucherMutation,
  useGetDashboardOverallQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = api;
