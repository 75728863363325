import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

export default function Notification() {
    const notification = useSelector(({ notification }) => notification)
    const notify = (success = true, msg) => {
        if (success) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }
    }

    useEffect(() => {
        const { open, success, message } = notification;
        if (open) {
            notify(success, message);
        }
    }, [notification])

    return (
        <div>
            <Toaster />
        </div>
    )
}