const initialState = {
    data: [
        {
            title: 'Dashboard',
            path: '/admin/dashboard',
            accountTypes: ['client', 'merchant', 'agent'],
            roles: [],
            icon: "fas fa-tv"
        },
        {
            title: 'Generate Vouchers',
            path: '/admin/pack',
            accountTypes: ['client', 'merchant'],
            roles: [],
            icon: "fas fa-table"
        },
        {
            title: 'Sell Vouchers',
            path: '/admin/sell-voucher',
            accountTypes: ['agent'],
            roles: [],
            icon: "fas fa-table"
        },
        {
            title: 'Offered Vouchers',
            path: '/admin/sell',
            accountTypes: ['merchant'],
            roles: [],
            icon: "fas fa-table"
        },
        {
            title: 'Reports',
            path: '/admin/reports',
            accountTypes: ['client'],
            roles: [],
            icon: "fas fa-tools"
        },
        {
            title: 'Settings',
            path: '/admin/settings',
            accountTypes: ['client'],
            roles: [],
            icon: "fas fa-tools"
        },
        {
            title: 'Branches',
            path: '/admin/branch',
            accountTypes: ['merchant'],
            roles: [],
            icon: "fas fa-users"
        },
        {
            title: 'Transactions',
            path: '/admin/transactions',
            accountTypes: ['merchant'],
            roles: [],
            icon: "fas fa-users"
        },
        {
            title: 'Transactions',
            path: '/admin/sales-transactions',
            accountTypes: ['agent'],
            roles: [],
            icon: "fas fa-users"
        },
    ]
};

const routes = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default routes