export const LOGIN_SUPER_ADMIN = "[SUPER_ADMIN] LOGIN";
export const LOGOUT_SUPER_ADMIN = "[SUPER_ADMIN] LOGOUT";
export const LOGIN_SUPER_ADMIN_START = "[SUPER_ADMIN] LOGIN START";
export const LOGIN_SUPER_ADMIN_SUCCESS = "[SUPER_ADMIN] LOGIN SUCCESS";
export const LOGIN_SUPER_ADMIN_FAIL = "[SUPER_ADMIN] LOGIN FAIL";
export const SET_SUPER_ADMIN_TOKEN = "[SUPER_ADMIN] SET_SUPER_ADMIN_TOKEN";
export const GET_SYSTEM_CONFIG = "[SUPER_ADMIN] GET_SYSTEM_CONFIG";
export const GET_SYSTEM_CONFIG_SUCCESS = "[SUPER_ADMIN] GET_SYSTEM_CONFIG SUCCESS";
export const GET_SYSTEM_CONFIG_FAIL = "[SUPER_ADMIN] GET_SYSTEM_CONFIG FAIL";


// FINANCIAL INSTITUTIONS
export const DELETE_FINANCIAL_INST = "[SUPER_ADMIN] DELETE FINANCIAL INST";
export const DELETE_FINANCIAL_INST_SUCCESS = "[SUPER_ADMIN] DELETE FINANCIAL INST SUCCESS";
export const DELETE_FINANCIAL_INST_FAIL = "[SUPER_ADMIN] DELETE FINANCIAL INST FAIL";

export const ADD_FINANCIAL_INST = "[SUPER_ADMIN] ADD FINANCIAL INST";
export const UPDATE_FINANCIAL_INST = "[SUPER_ADMIN] UPDATE FINANCIAL INST";
export const UPDATE_FINANCIAL_INST_SUCCESS = "[SUPER_ADMIN] UPDATE FINANCIAL INST SUCCESS";
export const UPDATE_FINANCIAL_INST_FAIL = "[SUPER_ADMIN] UPDATE FINANCIAL INST FAIL";

// REGIONS
export const ADD_REGION = "[SUPER_ADMIN] ADD REGION";
export const UPDATE_REGION = "[SUPER_ADMIN] UPDATE REGION";
export const DELETE_REGION = "[SUPER_ADMIN] DELETE REGION";
export const UPDATE_REGION_SUCCESS = "[SUPER_ADMIN] UPDATE REGION SUCCESS";

// INDUSTRIES
export const ADD_INDUSTRY = "[SUPER_ADMIN] ADD INDUSTRY";
export const UPDATE_INDUSTRY = "[SUPER_ADMIN] UPDATE INDUSTRY";
export const DELETE_INDUSTRY = "[SUPER_ADMIN] DELETE INDUSTRY";
export const UPDATE_INDUSTRY_SUCCESS = "[SUPER_ADMIN] UPDATE INDUSTRY SUCCESS";

// GENERAL CONFIG
// export const ADD_GENERAL_CONFIG = "[SUPER_ADMIN] ADD GENERAL CONFIG";
export const UPDATE_GENERAL_CONFIG = "[SUPER_ADMIN] UPDATE GENERAL CONFIG";
// export const DELETE_GENERAL_CONFIG = "[SUPER_ADMIN] DELETE GENERAL CONFIG";
export const UPDATE_GENERAL_CONFIG_SUCCESS = "[SUPER_ADMIN] UPDATE GENERAL CONFIG SUCCESS";

// CLIENT
export const GET_CLIENTS = "[SUPER_ADMIN] GET CLIENTS";
export const GET_MERCHANTS = "[SUPER_ADMIN] GET MERCHANTS";
export const GET_CLIENTS_SUCCESS = "[SUPER_ADMIN] GET CLIENTS SUCCESS";
export const GET_MERCHANTS_SUCCESS = "[SUPER_ADMIN] GET MERCHANTS SUCCESS";
export const ACTIVATE_CLIENTS = "[SUPER_ADMIN] ACTIVATE CLIENTS";
export const DEACTIVATE_CLIENTS = "[SUPER_ADMIN] DEACTIVATE CLIENTS";
export const ACTIVATE_MERCHANTS = "[SUPER_ADMIN] ACTIVATE MERCHANTS";
export const DEACTIVATE_MERCHANTS = "[SUPER_ADMIN] DEACTIVATE MERCHANTS";

export const GET_CLIENT_DETAILS = "[SUPER_ADMIN] GET CLIENT DETAILS";
export const GET_MERCHANT_DETAILS = "[SUPER_ADMIN] GET MERCHANT DETAILS";
export const CHANGE_SUBSCRIPTION_TYPE = "[SUPER_ADMIN] CHANGE SUBSCRIPTION TYPE";
export const UPDATE_CLIENT_COMMISSION = "[SUPER_ADMIN] UPDATE CLIENT COMMISSION";
export const ACCOUNT_BALANCE_TOP_UP = "[SUPER_ADMIN] ACCOUNT_BALANCE_TOP_UP";
export const UPDATE_SETUP_FEE = "[SUPER_ADMIN] UPDATE SETUP FEE";

export const loginSuperAdmin = (payload) => (dispatch) => dispatch({ type: LOGIN_SUPER_ADMIN, payload });
export const getSystemConfig = (payload) => (dispatch) => dispatch({ type: GET_SYSTEM_CONFIG, payload });
export const deleteFinancialInstitutions = (payload) => (dispatch) => dispatch({ type: DELETE_FINANCIAL_INST, payload });
export const addFinancialInstitutions = (payload) => (dispatch) => dispatch({ type: ADD_FINANCIAL_INST, payload });
export const updateFinancialInstitution = (payload) => (dispatch) => dispatch({ type: UPDATE_FINANCIAL_INST, payload });

export const addRegions = (payload) => (dispatch) => dispatch({ type: ADD_REGION, payload })
export const updateRegion = (payload) => (dispatch) => dispatch({ type: UPDATE_REGION, payload })
export const deleteRegions = (payload) => (dispatch) => dispatch({ type: DELETE_REGION, payload })

export const addIndustries = (payload) => (dispatch) => dispatch({ type: ADD_INDUSTRY, payload })
export const updateIndustry = (payload) => (dispatch) => dispatch({ type: UPDATE_INDUSTRY, payload })
export const deleteIndustries = (payload) => (dispatch) => dispatch({ type: DELETE_INDUSTRY, payload })

// export const addGeneralConfigs = (payload) => (dispatch) => dispatch({ type: ADD_GENERAL_CONFIG, payload })
export const updateGeneralConfigs = (payload) => (dispatch) => dispatch({ type: UPDATE_GENERAL_CONFIG, payload })
// export const deleteGeneralConfigs = (payload) => (dispatch) => dispatch({ type: DELETE_GENERAL_CONFIG, payload })
export const getClients = (payload) => (dispatch) => dispatch({ type: GET_CLIENTS, payload })
export const getMerchants = (payload) => (dispatch) => dispatch({ type: GET_MERCHANTS, payload })
export const activateClients = (payload) => (dispatch) => dispatch({ type: ACTIVATE_CLIENTS, payload })
export const deactivateClients = (payload) => (dispatch) => dispatch({ type: DEACTIVATE_CLIENTS, payload })
export const activateMerchants = (payload) => (dispatch) => dispatch({ type: ACTIVATE_MERCHANTS, payload })
export const deactivateMerchants = (payload) => (dispatch) => dispatch({ type: DEACTIVATE_MERCHANTS, payload })
export const getClientDetails = (payload) => (dispatch) => dispatch({ type: GET_CLIENT_DETAILS, payload })
export const getMerchantDetails = (payload) => (dispatch) => dispatch({ type: GET_MERCHANT_DETAILS, payload })
export const changeSubscriptionType = (payload) => (dispatch) => dispatch({ type: CHANGE_SUBSCRIPTION_TYPE, payload })
export const updateClientCommission = (payload) => (dispatch) => dispatch({ type: UPDATE_CLIENT_COMMISSION, payload })
export const accountTopup = (payload) => (dispatch) => dispatch({ type: ACCOUNT_BALANCE_TOP_UP, payload })
export const updateSetupFee = (payload) => (dispatch) => dispatch({ type: UPDATE_SETUP_FEE, payload })