import jwt_decode from "jwt-decode"
import * as Actions from "store/actions";

const tokenStr = window.localStorage.getItem('token');
const logoUrl = window.localStorage.getItem('logoUrl');
let data = null;
let token = null;
if (tokenStr != null) {
  try {
    let tokenData = JSON.parse(tokenStr);
    token = tokenData?.token ?? null
    data = jwt_decode(token);
    if (logoUrl) {
      data.logoUrl = logoUrl;
    }
  } catch (error) {
    console.log(error);
  }
}

var initialState = {
  isLoggedIn: false,
  data,
  isLoggingIn: false,
  isFetchingUsers: false,
  token,
  users: [],
  isVerified: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOGGING_IN":
      return Object.assign({}, state, { isLoggingIn: action.status });

    case "IS_FETCHING_USERS":
      return Object.assign({}, state, { isFetchingUsers: action.status });

    case "IS_CREATING_USER":
      return Object.assign({}, state, { isCreatingUser: action.status });

    case "LOGIN":
      return { ...state, isLoggedIn: true, data: action.payload };

    case "LOGOUT":
      return { ...initialState, token: null };

    case "SET_TOKEN":
      return Object.assign({}, state, {
        token: action.token,
        isLoggedIn: true,
      });
    // case "CREATE_USER":
    //   return { ...state, users: [...state.users, action.newUser] };

    // case "GET_USERS":
    //   return { ...state, users: action.users };
    case "SET_USERS":
    case Actions.GET_USERS_SUCCESS:
    case Actions.CREATE_USER_SUCCESS:
    case Actions.DELETE_USER_SUCCESS:
      return { ...state, users: action.users };

    case "SET_CREATE_USER_STATUS":
      return Object.assign({}, state, { createUserStatus: action.status });

    case "SET_USER_DATA":
      return { ...state, data: action.payload };

    case "SET_LOGO":
      return { ...state, data: { ...state.data, logoUrl: action.payload } }

    case Actions.VERIFY_USER_SUCCESS:
      return { ...state, isVerified: true };

    default:
      return state;
  }
};

export default user;
