import { Avatar } from "@mui/material";

const MerchantDataCard = ({ name, code, logoSrc }) => (
  <div
    className="flex justify-center gap-x-4 items-center mx-auto border-b pb-4 mb-2"
    style={{ maxWidth: "320px" }}
  >
    <div className="w-3/5">
      <div className="mb-4">
        <p className="text-sm">Merchant name</p>
        <p className="text-lg font-bold">{name}</p>
      </div>
      <div className="mb-4">
        <p className="text-sm">Merchant code</p>
        <p className="text-lg font-bold">{code}</p>
      </div>
    </div>
    <div className="w-2/5">
      {
        logoSrc ?
          <img
            alt="..."
            className="w-full rounded-full align-middle border-none shadow-lg"
            src={logoSrc}
          /> :
          <Avatar sx={{ backgroundColor: "#ec5f33", width: 72, height: 72 }}>
            {name?.slice(0, 2)}
          </Avatar>
      }
    </div>
  </div>
);

export default MerchantDataCard;
