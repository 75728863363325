import MerchantDataCard from "./MerchantDataCard";

const Header = ({ merchantName, merchantCode, logoSrc }) => (
  <div>
    <h1
      className="text-[#EC5F33] text-2xl mb-4 font-bold text-center"
      style={{ color: "#EC5F33" }}
    >
      UGIFT REDEMPTION PORTAL
    </h1>
    <MerchantDataCard
      name={merchantName}
      code={merchantCode}
      logoSrc={logoSrc}
    />
  </div>
);

export default Header;
