const Footer = () => (
  <div>
    {/* <img
      className="mx-auto"
      src="/assets/images/ugift-ad.jpg"
      alt="UGift coming soon"
    /> */}
  </div>
);

export default Footer;
