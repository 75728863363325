import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";
import client from ".";

function* getClients() {
  try {
    const { data: { data } } = yield call(API.getClients);
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: data })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to get clients. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* activateClients({ payload }) {
  try {
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { updatedOrganizations } } = yield call(API.activateOrganizations, payload);
    const updatedClientData = clientData.map(client => {
      const found = updatedOrganizations.find(({ id }) => id === client.id);
      if (found) {
        return { ...client, ...found }
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate clients. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* deactivateClients({ payload }) {
  try {
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { updatedOrganizations } } = yield call(API.deactivateOrganizations, payload);
    const updatedClientData = clientData.map(client => {
      const found = updatedOrganizations.find(({ id }) => id === client.id);
      if (found) {
        return { ...client, ...found }
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate clients. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* getClientDetails({ payload: id }) {
  try {
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { organization } } = yield call(API.getOrganizationDetails, id);
    const updatedClientData = clientData.length < 1 ? [organization] : clientData.map(client => {
      return organization.id === client.id ? organization : client;
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate clients. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* changeSubscriptionType({ payload: data }) {
  try {
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { updatedOrganizations } } = yield call(API.changeSubscriptionType, data);
    const updatedClientData = clientData.map(client => {
      const found = updatedOrganizations.find(({ id }) => id === client.id);
      if (found) {
        return { ...client, ...found }
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Subscription type updated',
      success: true,
    });

  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to change subscription type. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* updateClientCommission({ payload }) {
  try {
    const { clientId, commissionId, data } = payload;
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { updatedCommission } } = yield call(API.updateClientCommission, payload);
    const updatedClientData = clientData.map(client => {
      if (clientId == client.id) {
        const updatedClientCommission = { ...client, Commission: updatedCommission }
        return updatedClientCommission
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Voucher commission updated',
      success: true,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to update voucher commission. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* topupAccountBalance({ payload }) {
  try {
    const { organizationId, amount } = payload;
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { accountBalance } } = yield call(API.accountTopup, payload);
    const updatedClientData = clientData.map(client => {
      if (organizationId == client.id) {
        return {
          ...client,
          AccountBalance: client.AccountBalance ? {
            ...client.AccountBalance,
            amount
          } : accountBalance
        }
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Account balance successfully updated',
      success: true,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to update account balance. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* updateSetupFee({ payload }) {
  try {
    const { organizationId, amount } = payload;
    const clientData = yield select(({ client: { data } }) => data)
    const { data: { setupFee } } = yield call(API.updateSetupFee, payload);
    const updatedClientData = clientData.map(client => {
      if (organizationId == client.id) {
        return {
          ...client,
          SetupFee: client.SetupFee ? {
            ...client.SetupFee,
            amount
          } : setupFee
        }
      }
      return client
    })
    yield put({ type: Actions.GET_CLIENTS_SUCCESS, payload: updatedClientData })
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Setup fee successfully updated',
      success: true,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to update setup fee. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

export default [
  takeEvery(Actions.GET_CLIENTS, getClients),
  takeEvery(Actions.ACTIVATE_CLIENTS, activateClients),
  takeEvery(Actions.DEACTIVATE_CLIENTS, deactivateClients),
  takeEvery(Actions.GET_CLIENT_DETAILS, getClientDetails),
  takeEvery(Actions.CHANGE_SUBSCRIPTION_TYPE, changeSubscriptionType),
  takeEvery(Actions.UPDATE_CLIENT_COMMISSION, updateClientCommission),
  takeEvery(Actions.ACCOUNT_BALANCE_TOP_UP, topupAccountBalance),
  takeEvery(Actions.UPDATE_SETUP_FEE, updateSetupFee),
];
