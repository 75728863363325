import { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router";
import { useValidateVoucherMutation } from "services/pub";
import { VoucherContext } from "views/redemption-portal/context/VoucherContext";

const useRedeem = (setVoucherData) => {
  const voucherContext = useContext(VoucherContext);
  const [isValidVoucherCode, setIsValidVoucherCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { merchantcode } = useParams();
  const [validateVoucherRequest, { isLoading, isError, data, error }] =
    useValidateVoucherMutation();
  const { handleSubmit, control } = useForm();

  const handleVoucherCodeFormSubmission = handleSubmit((data) => {
    const token = captchaRef.current.getValue();
    setErrorMessage(null);
    if (!token) {
      return;
    }
    captchaRef.current.reset();
    validateVoucherRequest({
      token,
      ...data,
    });
  });

  useEffect(() => {
    if (data) {
      setVoucherData(data);
      setIsValidVoucherCode(true);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && error) {
      setErrorMessage(error?.data?.message || "An error occurred!");
    }
  }, [error]);

  const captchaRef = useRef(null);

  return {
    captchaRef,
    handleVoucherCodeFormSubmission,
    isValidVoucherCode,
    merchantcode,
    Controller,
    control,
    isLoading,
    errorMessage,
  };
};

export default useRedeem;
