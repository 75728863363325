import { ButtonGeneric } from "views/redemption-portal/common";
import RedemptionForm from "../redeem/RedemptionForm";
import useVerification from "./hooks/useVerification";
import VerificationForm from "./VerificationForm";

const VerificationPage = () => {
  const {
    msisdn,
    isVerified,
    verificationMethod,
    changeVerificationMethod,
    handleSubmitVerificationCode,
    handleSelectVerificationCodeMethod,
    isLoadingRequestVoucherVerificationCode,
    isLoadingSendVerificationCode,
    isVerificationCodeSent,
    errorMessage,
    errorRequestVoucherVerificationCode,
  } = useVerification();

  return (
    <div>
      {!isVerified ? (
        <div>
          {!isVerificationCodeSent ? (
            <>
              <p className="text-sm font-bold mb-4">
                Choose verification method
              </p>
              {errorRequestVoucherVerificationCode && (
                <p
                  style={{ color: "red" }}
                  className="mt-2 mb-4 text-center text-sm"
                >
                  {errorRequestVoucherVerificationCode?.data?.message ||
                    "An unexpected error occurred!"}
                </p>
              )}
              <p className="text-sm mb-0">
                1. Your would receive an OTP sent to your mobile number{" "}
                <span className="font-bold">{msisdn}</span>
              </p>
              <ButtonGeneric
                disabled={isLoadingRequestVoucherVerificationCode}
                label="OTP verification"
                onClick={() => handleSelectVerificationCodeMethod("otp")}
              />
              { /*
                <p className="text-sm mt-4 mb-0">
                  2. Dial <span className="font-bold">*161*600*170#</span> from
                  the linked mobile number{" "}
                  <span className="font-bold">{msisdn}</span> to get the
                  verification code
                </p>
                <ButtonGeneric
                  disabled={isLoadingRequestVoucherVerificationCode}
                  label="USSD verification"
                  onClick={() => handleSelectVerificationCodeMethod("ussd")}
                />
                  */
              }
            </>
          ) : (
            <VerificationForm
              verificationMethod={verificationMethod}
              changeVerificationMethod={changeVerificationMethod}
              handleVerification={handleSubmitVerificationCode}
              errorMessage={errorMessage}
              isLoading={isLoadingSendVerificationCode}
            />
          )}
        </div>
      ) : (
        <RedemptionForm />
      )}
    </div>
  );
};

export default VerificationPage;
