import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from '../services/api';
import { superApi } from '../services/superApi';
import { pub } from '../services/pub';

import createSagaMiddleware from "redux-saga";
// import thunk from "redux-thunk";
import reducers from "./reducers";
import ugvSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
// let store = createStore(reducers, applyMiddleware(thunk, sagaMiddleware));
let store = configureStore({
    reducer: {
        ...reducers,
        [api.reducerPath]: api.reducer,
        [superApi.reducerPath]: superApi.reducer,
        [pub.reducerPath]: pub.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            sagaMiddleware,
            superApi.middleware,
            api.middleware,
            pub.middleware,
        ),
});

sagaMiddleware.run(ugvSaga);

setupListeners(store.dispatch)

export default store;