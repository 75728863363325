import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRedeemMerchantMutation } from "services/pub";
import { MerchantContext } from "views/redemption-portal/context/MerchantContext";
import { VoucherContext } from "views/redemption-portal/context/VoucherContext";

const useRedemptionForm = () => {
  const voucherContext = useContext(VoucherContext);
  const merchantContext = useContext(MerchantContext);
  const { id: merchantId } = merchantContext;
  const [redeemMerchantRequest, { isLoading, data, error }] =
    useRedeemMerchantMutation();
  const {
    voucherAmountRemaining,
    voucher: { id: voucherId },
  } = voucherContext;
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [transactionResponse, setTransactionResponse] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleRedemptionFormSubmit = handleSubmit(
    ({ amountToRedeem: amount }) => {
      setErrorMessage();
      redeemMerchantRequest({
        amount,
        voucherId,
        merchantId,
      });
    }
  );

  useEffect(() => {
    if (errors.amountToRedeem) {
      setErrorMessage(
        `Amount must be in the range 10 - ${voucherAmountRemaining}`
      );
    }
  }, [errors?.amountToRedeem]);

  useEffect(() => {
    if (data) {
      setTransactionResponse(data);
      setTransactionStatus(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.data?.message || "An unexpected error occurred");
    }
  }, [error]);

  return {
    transactionStatus,
    transactionResponse,
    handleRedemptionFormSubmit,
    voucherAmountRemaining,
    Controller,
    control,
    errorMessage,
    isLoading,
  };
};

export default useRedemptionForm;
