import { combineReducers } from "redux";
import notification from "./notification";
import user from "./user";
import { superAdmin, client, merchant } from "./superAdmin";
import pack from "./pack";
import product from "./product";
import voucherConfig from "./voucherConfig";
import common from "./common";
import route from "./route";

export default {
  notification,
  user,
  client,
  merchant,
  superAdmin,
  pack,
  product,
  voucherConfig,
  common,
  route,
};
