import * as Actions from "store/actions";

var initialState = {
    regions: [],
    industries: [],
    merchants: [],
    commission: 0,
    financialInstitutions: [],
    searchTerm: "",
};

const common = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_COMMON_SUCCESS:
            return Object.assign({}, state, {
                regions: action.regions,
                industries: action.industries,
                merchants: action.merchants,
                commission: action.commission,
                financialInstitutions: action.financialInstitutions,
            });
        case Actions.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            }
        default:
            return state;
    }
}

export default common