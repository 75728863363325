export const CREATE_VOUCHER_CONFIG = "[VOUCHER CONFIG] CREATE VOUCHER CONFIG";
export const UPDATE_VOUCHER_CONFIG = "[VOUCHER CONFIG] UPDATE VOUCHER CONFIG";
export const CREATE_VOUCHER_CONFIG_SUCCESS = "[VOUCHER CONFIG] CREATE VOUCHER CONFIG SUCCESS";
export const FETCH_VOUCHER_CONFIG = "[VOUCHER CONFIG] FETCH VOUCHER CONFIG";
export const FETCH_VOUCHER_CONFIG_SUCCESS = "[VOUCHER CONFIG] FETCH VOUCHER CONFIG SUCCESS";
export const DELETE_VOUCHER_CONFIG = "[VOUCHER CONFIG] DELETE VOUCHER CONFIG";

export const createVoucherConfig = (payload) => (dispatch) => dispatch({ type: CREATE_VOUCHER_CONFIG, payload })
export const updateVoucherConfig = (payload) => (dispatch) => dispatch({ type: UPDATE_VOUCHER_CONFIG, payload })
export const fetchVoucherConfig = (payload) => (dispatch) => dispatch({ type: FETCH_VOUCHER_CONFIG, payload })
export const deleteVoucherConfig = (payload) => (dispatch) => dispatch({ type: DELETE_VOUCHER_CONFIG, payload })
