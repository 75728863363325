import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import {
  BreadcrumbsCustom,
  ButtonWithDescription,
  Container,
  Footer,
  Header,
} from "../../common";
import { Route, Switch } from "react-router";
import RedeemPage from "../redeem/Redeem";
import BuyPage from "../buy/Buy";
import useHome from "./hooks/useHome";
import { useContext } from "react";
import { VoucherContext } from "views/redemption-portal/context/VoucherContext";
import { MerchantContext } from "views/redemption-portal/context/MerchantContext";

export default function RedemptionPortalHome() {
  const {
    path,
    url,
    isLoading,
    merchantName,
    merchantCode,
    Organization,
    error,
    voucherData,
    setVoucherData,
  } = useHome();

  return (
    <MerchantContext.Provider value={Organization}>
      <VoucherContext.Provider value={voucherData}>
        <Container isLoading={isLoading} error={error}>
          <Header
            merchantName={merchantName}
            merchantCode={merchantCode}
            logoSrc={Organization?.logoUrl}
          />
          <div className="mx-auto" style={{ maxWidth: "320px" }}>
            <Switch>
              <Route exact path={path}>
                <BreadcrumbsCustom />
                <ButtonWithDescription
                  icon={<ShoppingBagOutlinedIcon sx={{ fontSize: 36 }} />}
                  title="Redeem Voucher"
                  description="Get items from merchant"
                  link={`/redemption/${merchantCode}/redeem`}
                />
                <ButtonWithDescription
                  icon={<PaymentsOutlinedIcon sx={{ fontSize: 36 }} />}
                  title={`Buy a ${merchantName} Voucher`}
                  description={`Get a ${merchantName} voucher`}
                  link={`/redemption/${merchantCode}/buy`}
                />
                <ButtonWithDescription
                  icon={<PaymentsOutlinedIcon sx={{ fontSize: 36 }} />}
                  title="Buy a UGift Voucher"
                  description="Get a UGift voucher"
                  link={`/redemption/${merchantCode}/buy/ugift`}
                />
              </Route>
              <Route path={`${path}/redeem`}>
                <RedeemPage setVoucherData={setVoucherData} />
              </Route>
              <Route path={`${path}/buy/:type`}>
                <BuyPage />
              </Route>
              <Route path={`${path}/buy`}>
                <BuyPage />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Container>
      </VoucherContext.Provider>
    </MerchantContext.Provider>
  );
}
