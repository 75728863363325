import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useHistory, useParams } from "react-router";
import { ButtonGeneric } from "views/redemption-portal/common";

const TransactionResponse = ({ transactionId, amount }) => {
  const history = useHistory();
  const { merchantcode } = useParams();
  return (
    <div>
      <div className="text-center mb-8">
        <CheckCircleOutlineOutlinedIcon
          sx={{ fontSize: 75, color: "#3BB54A" }}
        />
        <p className="font-bold text-lg" style={{ color: "#3BB54A" }}>
          Redemption Successful
        </p>
      </div>

      <div className="mb-4">
        <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
          Transaction ID
        </p>
        <p className="text-xl font-bold">{transactionId}</p>
      </div>

      <div className="mb-4">
        <p className="text-xs font-bold" style={{ color: "#EC5F33" }}>
          Amount redeemed
        </p>
        <p className="text-xl font-bold">GHS {amount}</p>
      </div>

      <ButtonGeneric
        onClick={() => history.push(`/redemption/${merchantcode}`)}
        label="Go to portal home"
      />
    </div>
  );
};

export default TransactionResponse;
