import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";

function* createProduct({ payload: { data, onComplete } }) {
  try {
    let { products } = yield select(({ product }) => product);
    const { data: product } = yield call(API.createProduct, data);
    const updatedProducts = [...products, product]
    yield put({ type: Actions.FETCH_PRODUCTS_SUCCESS, products: updatedProducts })
    console.log(product);

    onComplete(true);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Product successfully created!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    onComplete(false);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to create product. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* fetchProducts() {
  try {
    const { data } = yield call(API.fetchProducts);
    yield put({ type: Actions.FETCH_PRODUCTS_SUCCESS, products: data })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to fetch products. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

// TODO: update Product

export default [
  takeEvery(Actions.CREATE_PRODUCT, createProduct),
  takeEvery(Actions.FETCH_PRODUCTS, fetchProducts),
];
