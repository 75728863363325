import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { useParams } from "react-router";
import { BreadcrumbsCustom } from "views/redemption-portal/common";
import { ButtonGeneric } from "views/redemption-portal/common";
import BuyUGift from "./BuyUGift";
import BuyMerchant from "./BuyMerchant";

const BuyPage = () => {
  const { merchantcode, type } = useParams();
  return (
    <div>
      <BreadcrumbsCustom
        breadcrumbs={[
          { label: "Home", link: `/redemption/${merchantcode}` },
          { label: type === "ugift" ? "Buy UGift Voucher" : `Buy Voucher` },
        ]}
      />
      {type === "ugift" ? <BuyUGift /> : <BuyMerchant />}
    </div>
  );
};

export default BuyPage;
