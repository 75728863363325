import { ChevronRight } from "@mui/icons-material";
import { Button, Icon } from "@mui/material";
import { Link } from "react-router-dom";

export const ButtonWithDescription = ({ icon, title, description, link }) => (
  <Link to={link}>
    <div
      className="flex items-center text-white p-4 mx-auto rounded-sm mb-8"
      style={{ maxWidth: "320px", background: "#EC5F33", borderRadius: "10px" }}
    >
      <div className="w-16">{icon}</div>
      <div className="flex justify-between w-full items-center">
        <div>
          <p className="font-bold mb-1">{title}</p>
          <p className="text-xs">{description}</p>
        </div>
        <div>
          <ChevronRight sx={{ fontSize: 32 }} />
        </div>
      </div>
    </div>
  </Link>
);

export const ButtonGeneric = (props) => (
  <Button
    fullWidth
    variant="contained"
    sx={{
      textTransform: "capitalize",
      backgroundColor: "#EC5F33",
      margin: "24px 0",
    }}
    {...props}
  >
    {props.label}
  </Button>
);
