import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";

function* getMerchants() {
  try {
    const {
      data: { data },
    } = yield call(API.getMerchants);
    yield put({ type: Actions.GET_MERCHANTS_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to get clients. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* activateMerchants({ payload }) {
  try {
    const merchantData = yield select(({ merchant: { data } }) => data);
    const {
      data: { updatedOrganizations },
    } = yield call(API.activateOrganizations, payload);
    const updatedMerchantData = merchantData.map((merchant) => {
      const found = updatedOrganizations.find(({ id }) => id === merchant.id);
      if (found) {
        return { ...merchant, ...found };
      }
      return merchant;
    });
    yield put({
      type: Actions.GET_MERCHANTS_SUCCESS,
      payload: updatedMerchantData,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate merchant(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* getMerchantDetails({ payload: id }) {
  try {
    const merchantData = yield select(({ merchant: { data } }) => data);
    const {
      data: { organization },
    } = yield call(API.getOrganizationDetails, id);
    const updatedMerchantData =
      merchantData.length < 1
        ? [organization]
        : merchantData.map((merchant) => {
            return organization.id === merchant.id ? organization : merchant;
          });
    yield put({
      type: Actions.GET_MERCHANTS_SUCCESS,
      payload: updatedMerchantData,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to get merchant data. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* deactivateMerchants({ payload }) {
  try {
    const merchantData = yield select(({ merchant: { data } }) => data);
    const {
      data: { updatedOrganizations },
    } = yield call(API.deactivateOrganizations, payload);
    const updatedMerchantData = merchantData.map((merchant) => {
      const found = updatedOrganizations.find(({ id }) => id === merchant.id);
      if (found) {
        return { ...merchant, ...found };
      }
      return merchant;
    });
    yield put({
      type: Actions.GET_MERCHANTS_SUCCESS,
      payload: updatedMerchantData,
    });
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to activate merchant(s). ${e.response?.data?.message}`,
      success: false,
    });
  }
}

export default [
  takeEvery(Actions.GET_MERCHANTS, getMerchants),
  takeEvery(Actions.ACTIVATE_MERCHANTS, activateMerchants),
  takeEvery(Actions.DEACTIVATE_MERCHANTS, deactivateMerchants),
  takeEvery(Actions.GET_MERCHANT_DETAILS, getMerchantDetails),
];
