import { useSelector } from "react-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "utils/api";

function* createVoucherConfig({ payload: { data, onComplete } }) {
  try {
    let { voucherConfigs } = yield select(({ voucherConfig }) => voucherConfig);
    const { data: voucherConfig } = yield call(API.createVoucherConfig, data);
    const updatedVoucherConfigs = [...voucherConfigs, voucherConfig]
    yield put({ type: Actions.FETCH_VOUCHER_CONFIG_SUCCESS, voucherConfigs: updatedVoucherConfigs })
    console.log(updatedVoucherConfigs);

    onComplete(true);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Voucher configuration successfully created!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    onComplete(false);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to create voucher configuration. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* updateVoucherConfig({ payload: { data, onComplete } }) {
  try {
    let { voucherConfigs } = yield select(({ voucherConfig }) => voucherConfig);
    yield call(API.updateVoucherConfig, { data, id: data.id });
    const updatedVoucherConfigs = voucherConfigs.map(config => {
      return config.id == data.id ? data : config;
    })
    yield put({ type: Actions.FETCH_VOUCHER_CONFIG_SUCCESS, voucherConfigs: updatedVoucherConfigs })
    console.log(updatedVoucherConfigs);

    onComplete(true);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Voucher configuration successfully updated!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    onComplete(false);
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to update voucher configuration. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* fetchVoucherConfig() {
  try {
    const { data } = yield call(API.fetchVoucherConfig);
    yield put({ type: Actions.FETCH_VOUCHER_CONFIG_SUCCESS, voucherConfigs: data })
  } catch (e) {
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to fetch voucher configuration data. ${e.response?.data?.message}`,
      success: false,
    });
  }
}

function* deleteVoucherConfig({ payload: id }) {
  try {
    let { voucherConfigs } = yield select(({ voucherConfig }) => voucherConfig);
    yield call(API.deleteVoucherConfig, id)
    const updatedVoucherConfigs = voucherConfigs.filter(config => config.id != id)
    yield put({ type: Actions.FETCH_VOUCHER_CONFIG_SUCCESS, voucherConfigs: updatedVoucherConfigs })

    yield put({
      type: "SHOW_NOTIFICATION",
      message: 'Voucher configuration successfully deleted!',
      success: true,
    });

  } catch (e) {
    console.log(e)
    yield put({
      type: "SHOW_NOTIFICATION",
      message: `Failed to save voucher configuration. ${e.response?.data?.message}`,
      success: false,
    });
  }
}


// TODO: update Product

export default [
  takeEvery(Actions.CREATE_VOUCHER_CONFIG, createVoucherConfig),
  takeEvery(Actions.UPDATE_VOUCHER_CONFIG, updateVoucherConfig),
  takeEvery(Actions.FETCH_VOUCHER_CONFIG, fetchVoucherConfig),
  takeEvery(Actions.DELETE_VOUCHER_CONFIG, deleteVoucherConfig),
];
